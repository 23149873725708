export const reviewsList = [
    {
        autor: 'Rocio Ancina',
        id: 0,
        position: "Manager L'obrador",
        rating: 5
    },
    {
        autor: 'Carolina Gentile',
        id: 1,
        position: "Manager Brut de Corte",
        rating: 4
    }
]